interface FaqGroup {
  label: string
  faqs: { question: string, answer: string }[]
}

export function useLandingMeta() {
  const { t, tm } = useI18n()
  const title = t('landing.seo.title')
  const description = t('landing.seo.description')

  const features = tm('plans.table.PRO.features') as string[]

  const faqGroups = tm('landing.faqsSection.scopedFaqs') as FaqGroup[]

  const prices = {
    PRO: t('plans.table.PRO.price').match(/\d+/)?.[0],
    BASIC: t('plans.table.BASIC.price').match(
      /\d+/,
    )?.[0],
  }

  const reviews = [
    {
      author: 'Francesco Baschieri',
      body: t('landing.testimonalsSection.testimonials.1'),
      rating: 5,
    },
    {
      author: 'Marco Selis',
      body: t('landing.testimonalsSection.testimonials.2'),
      rating: 5,
    },
  ]

  useSeoMeta({
    title,
    description,
  })

  useSchemaOrg([
    {
      '@context': 'https://schema.org',
      '@type': 'Service',
      'serviceType': t('landing.seo.serviceType'),
      'slogan': title,
      'provider': {
        '@type': 'Organization',
        'name': 'VLK Studio s.r.l.s',
        'address': {
          '@type': 'PostalAddress',
          'streetAddress': 'Via Pietro Martini 31',
          'postalCode': '09016',
          'addressLocality': 'Iglesias',
          'addressRegion': 'SU',
          'addressCountry': 'IT',
        },
      },
      'application': {
        '@type': 'SoftwareApplication',
        'name': 'Foodlista',
        'applicationCategory': 'BusinessApplication',
        'operatingSystem': 'Any',
        'features': features,
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '5',
          'reviewCount': '2',
        },
        'review': reviews.map(r => ({
          '@type': 'Review',
          'author': {
            '@type': 'Person',
            'name': r.author,
          },
          'datePublished': '2024-02-01',
          'reviewBody': r.body,
          'reviewRating': {
            '@type': 'Rating',
            'bestRating': '5',
            'ratingValue': r.rating,
            'worstRating': '1',
          },
        })),
        'offers': {
          '@type': 'AggregateOffer',
          'itemOffered': {
            '@type': 'Service',
            'name': t('landing.pricingSection.prehead'),
          },
          'priceCurrency': 'EUR',
          'highPrice': prices.PRO,
          'lowPrice': prices.BASIC,
          'includesObject': ['BASIC', 'PRO'].map((type) => {
            const name = t(`plans.table.${type}.name`)
            const features: string[] = tm(
              `plans.table.${type}.features`,
            )
            const description: string[] = tm(
              `plans.table.${type}.description`,
            )

            return {
              '@type': 'Offer',
              'name': name,
              'price': prices[type as 'PRO' | 'BASIC'],
              'description': `${description}: ${features.join(', ')}`,
            }
          }),
        },
      },
    },
    {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': faqGroups.flatMap(({ faqs }) => faqs.map(faq => ({
        '@type': 'Question',
        'name': faq.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': faq.answer,
        },
      }))),
    },
  ])
}
