<script setup lang="ts">
const localePath = useLocalePath()
const plans = ['BASIC', 'PRO'] as const
</script>

<template>
  <section id="pricing-section" class="flex flex-col items-center gap-8 px-8 py-16">
    <div class="w-full space-y-4 text-center">
      <small
        class="from-primary-600 to-primary-600 bg-gradient-to-r via-pink-500 bg-clip-text font-semibold uppercase text-transparent"
      >
        {{ $t("landing.pricingSection.prehead") }}
      </small>
      <h2 class="mx-auto max-w-lg text-balance text-center text-4xl font-bold">
        {{ $t("landing.pricingSection.title") }}
      </h2>
    </div>

    <div
      class="flex w-full max-w-3xl flex-col overflow-hidden rounded-xl border border-gray-300 md:flex-row dark:border-gray-800"
    >
      <div
        v-for="plan in plans"
        :key="plan"
        class="flex basis-1/2 flex-col items-start gap-4 p-6 md:p-8"
        :class="{
          'border-r dark:border-gray-800':
            plan !== 'PRO',
          'from-primary-900 via-primary-900 bg-gradient-to-tl to-pink-600 p-6 md:p-8':
            plan === 'PRO',
        }"
      >
        <p
          class="rounded-full px-2 py-1 text-sm font-medium"
          :class="{
            'bg-primary-100 text-primary-600':
              plan !== 'PRO',
            'from-primary-600 bg-gradient-to-br to-pink-400 text-white':
              plan === 'PRO',
          }"
        >
          {{ $t(`plans.table.${plan}.name`) }}
        </p>

        <div>
          <h3 class="text-3xl font-bold">
            {{ $t(`plans.table.${plan}.price`) }}
            <span
              class="text-xl"
              :class="{
                'text-gray-600 dark:text-gray-400':
                  plan !== 'PRO',
                'text-gray-300 dark:text-gray-300':
                  plan === 'PRO',
              }"
            >
              {{ $t("plans.table.timeUnit") }}
            </span>
          </h3>
          <p
            class="text-xs"
            :class="{
              'text-gray-600 dark:text-gray-400':
                plan !== 'PRO',
              'text-gray-300 dark:text-gray-300':
                plan === 'PRO',
            }"
          >
            {{ $t("plans.table.vatText") }}
          </p>
        </div>

        <p
          class="grow"
          :class="{
            'text-gray-600 dark:text-gray-400':
              plan !== 'PRO',
            'text-gray-300 dark:text-gray-200':
              plan === 'PRO',
          }"
        >
          {{ $t(`plans.table.${plan}.description`) }}
        </p>

        <ul class="my-4 flex grow flex-col gap-3">
          <li
            v-for="feature, i in $tm(
              `plans.table.${plan}.features`,
            )"
            :key="i"
            class="flex items-center gap-3 text-sm font-medium"
          >
            <div
              class="bg-primary-100 text-primary-600 flex items-center rounded-full p-1"
              :class="{
                'from-primary-600 bg-gradient-to-br to-pink-400 text-white':
                  plan === 'PRO',
              }"
            >
              <UIcon name="i-tabler-check" class="size-4" />
            </div>
            <span>{{ feature }}</span>
          </li>
        </ul>

        <UButton
          trailing-icon="i-tabler-arrow-narrow-right"
          block
          :to="localePath(`/signup?plan=${plan}`)"
          :variant="plan === 'PRO' ? 'outline' : 'solid'"
          :class="{ 'ring-primary-300 text-primary-300 dark:text-primary-300': plan === 'PRO' }"
        >
          {{ $t("landing.pricingSection.plans.cta") }}
        </UButton>
      </div>
    </div>
    <i18n-t
      keypath="landing.pricingSection.subtext.plain"
      tag="p"
      class="text-gray-600 dark:text-gray-400"
      scope="global"
    >
      <template #bold>
        <strong>{{ $t("landing.pricingSection.subtext.bold") }}</strong>
      </template>
    </i18n-t>
  </section>
</template>
