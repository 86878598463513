<script setup lang="ts">
const { tm } = useI18n()

const scopedFaqs = computed(() => (tm('landing.faqsSection.scopedFaqs') as any[]))
</script>

<template>
  <section
    class="relative flex flex-col items-center gap-8 bg-white px-4 py-16 dark:bg-gray-800"
  >
    <h2 class="text-balance text-center text-2xl font-bold text-gray-700 dark:text-gray-300">
      {{ $t("landing.faqsSection.headline") }}
    </h2>

    <UIcon
      name="i-tabler-question-mark"
      class="text-primary-400 absolute -top-4 left-1/2 size-48 -translate-x-1/2 opacity-5"
    />

    <div class="z-10 flex w-full max-w-3xl">
      <UAccordion
        :items="scopedFaqs"
        class="space-y-2"
      >
        <template #default="{ item, open }">
          <UButton
            color="white"
            variant="ghost"
            size="xl"
          >
            <template #leading>
              <span class="transition-color text-left font-medium text-gray-700 duration-200 dark:text-gray-300" :class="[open && 'text-primary-600 dark:text-primary-400']">
                {{ item.label }}
              </span>
            </template>
            <template #trailing>
              <UIcon
                name="i-heroicons-chevron-right-20-solid"
                class="ms-auto size-6 shrink-0 text-gray-700 transition-all duration-200 dark:text-gray-300"
                :class="[open && 'text-primary-600 dark:text-primary-400 rotate-90']"
              />
            </template>
          </UButton>
        </template>
        <template #item="{ item }">
          <ul class="space-y-4">
            <li v-for="faq in item.faqs" :key="faq.label" class="space-y-2">
              <h3 class="font-medium dark:text-gray-300">
                {{ faq.question }}
              </h3>
              <p class="prose max-w-full text-sm leading-relaxed text-gray-600 dark:prose-invert dark:text-gray-400 [&_a]:text-inherit" v-html="faq.answer" />
            </li>
          </ul>
        </template>
      </UAccordion>
    </div>
  </section>
</template>
