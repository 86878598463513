<script setup lang="ts">
import AddressInput from '~/domains/common/components/AddressInput.vue'
import Logo from '~/domains/common/components/Logo.vue'
import CardsSection from '~/domains/landing/components/CardsSection.vue'
import ClosingSection from '~/domains/landing/components/ClosingSection.vue'
import FaqsSection from '~/domains/landing/components/FaqsSection.vue'
import HowItWorksSection from '~/domains/landing/components/HowItWorksSection.vue'
import PricingSection from '~/domains/landing/components/PricingSection.vue'
import StepperItem from '~/domains/landing/components/StepperItem.vue'
import TestimonialsSection from '~/domains/landing/components/TestimonialsSection.vue'
import { useLandingMeta } from '~/domains/landing/composables/useLandingMeta'
import { useGetCurrentUserProfile } from '~/domains/users/queries'

definePageMeta({
  title: 'landing.seo.title',
  layout: 'default',
  colorMode: 'dark',
})

useLandingMeta()

const localePath = useLocalePath()

interface RestaurantModel {
  name: null | string
  address: null | string
  city: null | string
  region: null | string
  country: null | string
  streetNumber: null | string
  route: null | string
  postalCode: null | string
  coordinates: null | { lat: number, lng: number }
}

const restaurantState = reactive<RestaurantModel>({
  name: null,
  address: null,
  city: null,
  region: null,
  country: null,
  streetNumber: null,
  route: null,
  postalCode: null,
  coordinates: null,
})

async function continueOnboarding() {
  const coords = restaurantState.coordinates as { lat: number, lng: number } | null
  const { coordinates, ...restaurant } = restaurantState
  await navigateTo({ path: '/signup', query: { ...restaurant, coordinates_lat: coords?.lat, coordinates_lng: coords?.lng } })
}

// Approximate number of products in the database as of March 2024
const TOTAL_PRODUCTS = 1500

const user = await useGetCurrentUserProfile().catch(() => null)

function handleAddressInput(event: Event) {
  const target = event.target as HTMLInputElement
  restaurantState.address = target.value
}
</script>

<template>
  <main class="bg-gray-200 dark:bg-gray-900">
    <div class="flex flex-col items-center">
      <nav class="w-full border-b dark:border-gray-800">
        <div
          class="mx-auto flex max-w-5xl items-center justify-between px-4 py-3 md:px-8"
        >
          <Logo class="w-24 text-gray-600 dark:text-gray-400" />
          <UButton
            v-if="user"
            :to="localePath('/dashboard')"
            color="gray"
            size="sm"
            class="rounded-full px-3"
          >
            {{ $t("landing.nav.dashboardButtonLabel") }}
          </UButton>
          <UButton
            v-else
            :to="localePath('/login')"
            color="gray"
            size="sm"
            class="rounded-full px-3"
          >
            {{ $t("landing.nav.signInButtonText") }}
          </UButton>
        </div>
      </nav>
      <section
        class="flex w-full max-w-5xl flex-col justify-between gap-8 px-6 py-8 md:flex-row md:py-32"
      >
        <div
          class="flex h-[60dvh] flex-col items-center justify-center gap-2 md:h-auto md:items-start"
        >
          <p class="text-sm font-medium text-gray-700 dark:text-gray-400">
            {{ $t("landing.hero.preHead") }}
          </p>
          <h1
            class="from-primary-600 animate-gradient max-w-xl text-balance bg-gradient-to-r via-pink-500 to-red-600 bg-clip-text py-2 text-center text-5xl font-bold text-transparent sm:text-6xl md:text-left"
          >
            {{ $t("landing.hero.headline") }}
          </h1>
          <p
            id="create-link-label"
            class="mb-1 mt-8 text-gray-600 dark:text-gray-400"
          >
            {{ $t("landing.hero.linkInput.label") }}
          </p>
          <form method="GET" action="/signup" class="w-full">
            <div
              class="relative mx-auto flex w-full max-w-xs items-center overflow-hidden rounded-lg p-0.5 md:mx-0"
            >
              <AddressInput
                v-model="restaurantState"
                class="relative min-w-0 grow"
                :handle-input="handleAddressInput"
                :ui="{ rounded: 'rounded-r-none' }"
              />
              <UButton
                size="lg"
                color="primary"
                icon="i-tabler-sparkles"
                class="relative rounded-l-none"
                :aria-label="$t('landing.hero.linkInput.buttonLabel')"
                :disabled="!restaurantState.address"
                @click="continueOnboarding"
              />
            </div>
          </form>
        </div>
        <div
          class="relative z-10 h-[30rem] shrink grow-0 self-center md:w-full md:self-stretch"
        >
          <NuxtPicture
            src="/assets/images/home/mockup.png"
            :img-attrs="{
              class: 'float-right h-full w-auto object-contain',
              alt: $t('landing.hero.mockupImageAlt'),
            }"
            sizes="300px sm:600px"
          />
          <div
            class="absolute inset-0 z-50 bg-gradient-to-r from-transparent to-gray-200 dark:to-gray-900"
          />
        </div>
      </section>
    </div>
    <div class="bg-primary-900 w-full px-8 py-4 text-white">
      <div class="text-primary-200 mx-auto flex max-w-5xl justify-between">
        <div class="flex flex-col">
          <span class="text-4xl font-bold">
            {{ $t("landing.stats.impressions.value") }}
          </span>
          <span>
            {{ $t("landing.stats.impressions.label") }}
          </span>
        </div>
        <div class="hidden flex-col md:flex">
          <span class="text-4xl font-bold">
            {{ $t("landing.stats.restaurants.value") }}
          </span>
          <span>
            {{ $t("landing.stats.restaurants.label") }}
          </span>
        </div>
        <div class="flex flex-col">
          <span class="flex gap-1 text-4xl font-bold">
            {{ TOTAL_PRODUCTS }} <UIcon name="i-tabler-bowl-chopsticks-filled" />
          </span>
          <span>
            {{ $t("landing.stats.products.label") }}
          </span>
        </div>
      </div>
    </div>
    <section
      id="steps"
      class="relative flex flex-col items-center gap-16 overflow-hidden bg-white px-8 pb-12 pt-16 dark:bg-gray-800"
    >
      <i18n-t
        tag="h2"
        scope="global"
        keypath="landing.stepsSection.headline.plain"
        class="text-balance text-center text-2xl font-bold text-gray-700 md:text-3xl dark:text-gray-400"
      >
        <template #highlighted>
          <span
            class="from-primary-600 bg-gradient-to-r via-purple-500 to-rose-400 bg-clip-text text-transparent"
          >
            {{ $t("landing.stepsSection.headline.highlighted") }}
          </span>
        </template>
      </i18n-t>

      <div class="flex gap-16">
        <NuxtPicture
          src="/assets/images/qrstand.png"
          :img-attrs="{
            class: 'h-96 w-auto object-contain',
            alt: $t('landing.stepsSection.imageAlt'),
          }"
          quality="70"
          class="hidden md:block"
          loading="lazy"
        />

        <NuxtPicture
          src="/assets/images/qrstand.png"
          :img-attrs="{
            class: 'w-full  object-cover',
            alt: $t('landing.stepsSection.imageAlt'),
          }"
          quality="60"
          class="absolute -bottom-8 left-1/2 h-3/4 w-full -translate-x-1/2 opacity-10 md:hidden"
          loading="lazy"
        />
        <div class="z-10 flex max-w-lg flex-col items-start">
          <StepperItem icon="i-tabler-home-plus">
            <template #title>
              {{ $t("landing.stepsSection.steps.1.title") }}
            </template>
            <template #description>
              {{ $t("landing.stepsSection.steps.1.description") }}
            </template>
          </StepperItem>
          <StepperItem icon="i-tabler-bowl">
            <template #title>
              {{ $t("landing.stepsSection.steps.2.title") }}
            </template>
            <template #description>
              {{ $t("landing.stepsSection.steps.2.description") }}
            </template>
          </StepperItem>
          <StepperItem icon="i-tabler-qrcode">
            <template #title>
              {{ $t("landing.stepsSection.steps.3.title") }}
            </template>
            <template #description>
              {{ $t("landing.stepsSection.steps.3.description") }}
            </template>
          </StepperItem>
        </div>
      </div>
    </section>
    <CardsSection />
    <HowItWorksSection />
    <TestimonialsSection />
    <PricingSection />
    <FaqsSection />
    <ClosingSection />
  </main>
</template>

<style scoped>
  .animate-gradient {
    background-size: 300%;
    animation: animatedgradient 6s ease infinite alternate;
  }

  @keyframes animatedgradient {
    0% {
      background-position: -50% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: -50% 50%;
    }
  }

  .animate-gradient-border {
    background: conic-gradient(#f43f5e, #f046cf, #f43f5e);

    animation: rotate-background 6s linear infinite;
  }

  @keyframes rotate-background {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
