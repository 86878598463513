<script setup lang="ts">
const CARDS: Array<{
  title: string
  description: string
  image: string
}> = [
  {
    title: 'landing.cardsSection.cards.1.title',
    description: 'landing.cardsSection.cards.1.description',
    image: '/assets/images/home/cards/food.jpg',
  },
  {
    title: 'landing.cardsSection.cards.2.title',
    description: 'landing.cardsSection.cards.2.description',
    image: '/assets/images/home/cards/cooking.jpeg',
  },
  {
    title: 'landing.cardsSection.cards.3.title',
    description: 'landing.cardsSection.cards.3.description',
    image: '/assets/images/home/cards/people.jpeg',
  },
]
</script>

<template>
  <div class="flex flex-col items-center gap-16 p-8 py-16 md:p-16">
    <div class="space-y-4 text-center">
      <i18n-t
        tag="h2"
        keypath="landing.cardsSection.headline.plain"
        scope="global"
        class="text-balance text-center text-3xl font-bold text-gray-700 dark:text-gray-400"
      >
        <template #highlighted>
          <span
            class="from-primary-600 to-primary-600 bg-gradient-to-r via-pink-500 bg-clip-text text-transparent"
          >
            {{ $t("landing.cardsSection.headline.highlighted") }}
          </span>
        </template>
      </i18n-t>
      <p class="max-w-lg text-gray-600 dark:text-gray-400">
        {{ $t("landing.cardsSection.description") }}
      </p>
    </div>
    <div
      class="flex w-full flex-col items-center justify-center gap-24 md:flex-row md:items-start"
    >
      <div v-for="card, i in CARDS" :key="i" class="flex w-72 flex-col gap-2">
        <NuxtPicture
          :src="card.image"
          class="mb-2 h-72 object-cover"
          :img-attrs="{
            class: 'h-full object-cover w-full',
            alt: $t(card.title),
          }"
          loading="lazy"
        />
        <h3 class="text-primary-500 text-xl font-semibold">
          {{ $t(card.title) }}
        </h3>
        <p>
          {{ $t(card.description) }}
        </p>
      </div>
    </div>
  </div>
</template>
