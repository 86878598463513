<script setup lang="ts">
const { t } = useI18n()
const runtimeConfig = useRuntimeConfig()

const testimonials = [
  {
    by: 'Francesco Baschieri',
    from: { name: 'Momenti', url: `${runtimeConfig.public.publicWebBaseUrl}/r/momenti` },
    text: t('landing.testimonalsSection.testimonials.1'),
  },
  {
    by: 'Marco Selis',
    from: { name: 'Food Mine', url: `${runtimeConfig.public.publicWebBaseUrl}/r/foodmine` },
    text: t('landing.testimonalsSection.testimonials.2'),
  },
]
</script>

<template>
  <section
    class="relative flex flex-col items-center gap-8 bg-white px-8 py-16 dark:bg-gray-800"
  >
    <h2
      class="text-balance text-center text-2xl font-bold text-gray-700 dark:text-gray-300"
    >
      {{ $t("landing.testimonalsSection.headline") }}
    </h2>

    <UIcon
      name="i-tabler-quote"
      class="text-primary-400 absolute -top-8 left-1/2 size-64 -translate-x-1/2 opacity-10"
    />

    <div class="z-10 flex max-w-md flex-col gap-8">
      <div
        v-for="testimonial in testimonials"
        :key="testimonial.by"
        class="rounded-lg bg-white p-8 text-center shadow dark:bg-gray-900"
      >
        <blockquote class="flex flex-col items-center gap-2">
          <div class="flex">
            <UIcon name="i-tabler-star-filled" class="text-primary-400" />
            <UIcon name="i-tabler-star-filled" class="text-primary-400" />
            <UIcon name="i-tabler-star-filled" class="text-primary-400" />
            <UIcon name="i-tabler-star-filled" class="text-primary-400" />
            <UIcon name="i-tabler-star-filled" class="text-primary-400" />
          </div>
          <q class="text-sm italic text-gray-700 dark:text-gray-300">
            {{ testimonial.text }}
          </q>
          <footer class="text-sm font-medium text-gray-800 dark:text-gray-400">
            <cite class="not-italic">{{ testimonial.by }}</cite>
            -
            <NuxtLink :to="testimonial.from.url" class="underline" target="_blank">
              {{ testimonial.from.name }}
            </NuxtLink>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</template>
