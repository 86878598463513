<script setup lang="ts">
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { onMounted } from 'vue'

const { t } = useI18n()

const { getImage } = useImage()

const SLIDES = [
  {
    src: getImage('/assets/images/home/dashboard_screenshot.png').url,
    text: t('landing.howItWorksSection.slideshow.1'),
  },
  {
    src: getImage('/assets/images/home/menu_editing_screenshot_flipped.png')
      .url,
    text: t('landing.howItWorksSection.slideshow.2'),
  },
  {
    src: getImage('/assets/images/home/public-menu-basic-screenshot.png').url,
    text: t('landing.howItWorksSection.slideshow.3'),
  },
  {
    src: getImage(
      '/assets/images/home/branding-editing-screenshot_flipped.png',
    ).url,
    text: t('landing.howItWorksSection.slideshow.4'),
  },
  {
    src: getImage('/assets/images/home/public-menu-rebranding-screenshot.png')
      .url,
    text: t('landing.howItWorksSection.slideshow.5'),
  },
]

useHead({
  link: SLIDES.map(slide => ({
    rel: 'preload',
    as: 'image',
    href: slide.src,
  })),
})

const src = ref(SLIDES[0].src)
const text = ref(SLIDES[0].text)

onMounted(async () => {
  gsap.registerPlugin(ScrollTrigger)

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '#how-it-works-section',
      start: 'top top',
      end: `+=${SLIDES.length * 100}%`,
      pin: true,
      scrub: true,
      fastScrollEnd: true,
    },
  })

  for (const [i, slide] of SLIDES.entries()) {
    tl.to('#how-it-works-image', {
      rotateY: (i === SLIDES.length - 1 ? 0 : 90) + 180 * i,
      duration: i === 0 || i === SLIDES.length - 1 ? 1 : 1.5,
      onStart() {
        src.value = slide.src
      },
      onReverseComplete() {
        const prevIndex = i === 0 ? 0 : i - 1
        src.value = SLIDES[prevIndex].src
      },
    })

    if (i < SLIDES.length - 1) {
      tl.to(
        '#image-description',
        {
          opacity: 0,
          filter: 'blur(10px)',
          duration: 0.4,
          onComplete() {
            text.value = SLIDES[i + 1].text
          },
        },
        i * 1.4 + 0.6 + i * 0.1,
      ).to(
        '#image-description',
        {
          opacity: 1,
          duration: 0,
          filter: 'blur(0px)',
          onReverseComplete() {
            text.value = slide.text
          },
        },
        i * 1.4 + 1 + i * 0.1,
      )
    }
  }
})
</script>

<template>
  <i18n-t
    tag="h2"
    keypath="landing.howItWorksSection.headline.plain"
    scope="global"
    class="text-balance text-center text-3xl font-bold text-gray-700 dark:text-gray-400"
  >
    <template #highlighted>
      <span
        class="from-primary-600 bg-gradient-to-r via-purple-500 to-rose-400 bg-clip-text text-transparent"
      >
        {{ $t("landing.howItWorksSection.headline.highlighted") }}
      </span>
    </template>
  </i18n-t>
  <img
    id="how-it-works-image"
    :src="src"
    :alt="text"
    class="z-50 h-[55dvh] rounded-xl shadow-xl"
  >

  <p id="image-description" class="text-gray-700 dark:text-gray-400">
    {{ text }}
  </p>
</template>

<style scoped>
  #how-it-works-section {
    perspective: 1500px;
    transform-style: preserve-3d;
  }
</style>
