<script setup lang="ts">
const localePath = useLocalePath()
</script>

<template>
  <div
    id="closing-section"
    class="flex flex-col items-center border-t px-16 py-64 dark:border-gray-800"
  >
    <section class="flex grow flex-col items-center justify-center gap-8 p-4">
      <i18n-t
        tag="h2"
        scope="global"
        keypath="landing.closingSection.headline.plain"
        class="max-w-xl text-balance text-center text-4xl font-bold text-gray-700 lg:text-5xl dark:text-gray-500"
      >
        <template #highlighted>
          <span
            class="from-primary-600 bg-gradient-to-r to-pink-400 bg-clip-text text-transparent"
          >
            {{ $t("landing.closingSection.headline.highlighted") }}
          </span>
        </template>
      </i18n-t>

      <div class="flex gap-4">
        <UButton
          id="signup-cta"
          :to="localePath(`/signup`)"
          color="primary"
          size="lg"
          class="rounded-full"
          trailing-icon="i-tabler-arrow-narrow-right"
        >
          {{ $t("landing.closingSection.cta") }}
        </UButton>
        <UButton to="mailto:info@foodlista.it" variant="link" color="white">
          {{ $t("landing.closingSection.contactCta") }}
        </UButton>
      </div>
    </section>
  </div>
</template>
